import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="explore-products-container">
      {/* POPULAR GAMES  */}
      <div className="titlee">
        <h5>Popular Games</h5>
      </div>
      <div className="games-list">
        {products?.map((item, index) => {
          return (
            <div
              key={index}
              className="game"
              onClick={() => navigate(`/product/${item?.name}`)}
            >
              <img
                src={`https://insanitymlbb.shop/${item?.image}`}
                alt="pro-img"
              />
              <h5 className="m-0">{item?.name}</h5>
              <button className="buy-now">Select Options</button>
            </div>
          );
        })}
      </div>
      {/* PREMIUM ACCOUNTS */}
      {/* <div className="popular-games">
        <div className="titlee">
          <h5>Premium Accounts</h5>
          <span onClick={() => navigate("/games")}>View More</span>
        </div>
        <div className="games-list">
          {products
            ?.filter((item) => item?.category === "Premium Accounts")
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className="game"
                  onClick={() => navigate(`/product/${item?.name}`)}
                >
                  <div className="game-img">
                    <img
                      src={`https://arisato.in/${item?.images[0]}`}
                      alt="pro-img"
                    />
                  </div>
                  <div className="game-content">
                    <h5 className="m-0">{item?.name}</h5>
                    <span>
                      <small>Starts ₹{item.cost[0]?.price}</small>
                    </span>
                  </div>
                </div>
              );
            })
            .splice(0, 6)}
        </div>
      </div> */}
      {/* PREMIUM ACCOUNTS */}
      {/* <div className="popular-games">
        <div className="titlee">
          <h5>Social Media Services</h5>
          <span onClick={() => navigate("/games")}>View More</span>
        </div>
        <div className="games-list">
          {products
            ?.filter((item) => item?.category === "Social Media Services")
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className="game"
                  onClick={() => navigate(`/product/${item?.name}`)}
                >
                  <div className="game-img">
                    <img
                      src={`https://topupplayground.com/${item?.images[0]}`}
                      alt="pro-img"
                    />
                  </div>
                  <div className="game-content">
                    <h5 className="m-0">{item?.name}</h5>
                    <span>
                      <small>Starts ₹{item.cost[0]?.price}</small>
                    </span>
                  </div>
                </div>
              );
            })
            .splice(0, 6)}
        </div>
      </div> */}
    </div>
  );
};

export default Games;
